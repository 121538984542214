
import { defineComponent, ref, reactive } from "vue";
import { getTopic } from "@/API/closing";
import { getTableInfo, saveTableInfo } from "@/API/forecast";
import blobDownload from "@/utils/blobDownload";
const tableHeight = window.innerHeight - 400;
export default defineComponent({
  emits: ["refresh-table"],
  props: {
    make: {
      type: String,
      default: ""
    },
    makeId: {
      type: Number,
      required: true
    }
  },
  setup(props, context) {
    const showPrompt = ref("");
    const programTable = reactive([
      {
        title: "Program Code",
        dataIndex: "programCode",
        key: "programCode",
        align: "left"
      },
      {
        title: "Program Name",
        dataIndex: "programName",
        key: "programName",
        align: "left",
        ellipsis: true
      },
      {
        title: "Make",
        dataIndex: "makes",
        key: "makes",
        align: "left",
        ellipsis: true
      },
      {
        dataIndex: "bu",
        key: "bu",
        align: "left",
        slots: { title: "buTitle", customRender: "bu" }
      },
      {
        title: "Offer Type",
        dataIndex: "offerTypes",
        key: "offerTypes",
        align: "left",
        ellipsis: true
      },
      {
        dataIndex: "closingActive",
        key: "closingActive",
        align: "center",
        slots: { title: "cActiveTitle", customRender: "closingActive" }
      },
      {
        title: "Comments",
        dataIndex: "comments",
        key: "comments",
        align: "center",
        slots: { customRender: "comments" },
        width: 300
      }
    ]);
    const programTableData: any = reactive([]);
    const programTableDataTemp: any = reactive([]);
    const statusVal = ref(undefined);

    const buTypeArr: any = ref([]);
    const selectedRowKeys = ref([]);
    const pStatusArr = ref([
      { lable: "Approved", value: "Approved" },
      { lable: "Released", value: "Released" }
    ]);
    const onSelectChange = (selectedRow: any) => {
      selectedRowKeys.value = selectedRow;
    };
    // 筛选表格框
    const visible = ref<boolean>(false);
    // bu小框
    const buVisible = ref<boolean>(false);
    const filterActive = ref<number>(2); //默认选中全部
    const filterBu = ref("All");
    // 帅选表格项，供下拉框使用；

    const pCodeArr = ref("");
    const pNameArr = ref("");
    const searchArr: any = [];
    // 筛选了pCode条件
    const filterPCode = () => {
      searchArr.length = 0;
      if (programTableData.length && pCodeArr.value) {
        programTableData.forEach((item: any) => {
          if (item.programCode.indexOf(pCodeArr.value) !== -1) {
            searchArr.push(item);
          }
        });
        programTableData.length = 0;
        programTableData.push(...searchArr);
      }
    };
    // 筛选了pName条件
    const filterPName = () => {
      searchArr.length = 0;
      if (programTableData.length && pNameArr.value) {
        programTableData.forEach((item: any) => {
          if (item.programName.indexOf(pNameArr.value) !== -1) {
            searchArr.push(item);
          }
        });
        programTableData.length = 0;
        programTableData.push(...searchArr);
      }
    };
    // 筛选了pstatus条件
    const filterPStatus = () => {
      searchArr.length = 0;
      if (programTableData.length && statusVal.value) {
        programTableData.forEach((item: any) => {
          if (item.programStatus == statusVal.value) {
            searchArr.push(item);
          }
        });
        programTableData.length = 0;
        programTableData.push(...searchArr);
      }
    };
    // 筛选bu列类型以供筛选
    const filterBU = () => {
      buTypeArr.value = [];
      if (programTableData.length) {
        programTableData.forEach((ele: any) => {
          if (!buTypeArr.value.includes(ele.bu)) {
            buTypeArr.value.push(ele.bu);
          }
        });
        buTypeArr.value.push("All");
      }
    };

    // 查询条件选中情况
    const changeSel = (value: any) => {
      statusVal.value = value;
    };
    // 查询按钮事件
    const filterSelArr = () => {
      programTableData.length = 0;
      programTableData.push(...programTableDataTemp);
      searchArr.length = 0;
      if (pCodeArr.value) {
        filterPCode();
      }
      if (pNameArr.value) {
        filterPName();
      }
      if (statusVal.value) {
        filterPStatus();
      }
      filterActive.value = 2; //默认选中全部
      filterBu.value = "All";
    };
    // 温馨提示
    const getTopicInfo = () => {
      getTopic({ page: "PROGRAM_BASE" }).then(res => {
        showPrompt.value = res;
      });
    };
    getTopicInfo(); //获取提示信息
    // 获取表格数据
    const getTable = () => {
      getTableInfo({ mcId: props.makeId }).then((res: any) => {
        programTableData.length = 0;
        programTableDataTemp.length = 0;
        programTableData.push(...res);
        programTableDataTemp.push(...res);
        context.emit("refresh-table");
        filterBU(); //筛选列表bu列类型
      });
    };
    getTable();
    const filterPActive = (param: number) => {
      searchArr.length = 0;
      if (programTableData.length) {
        if (param == 0) {
          programTableData.forEach((item: any) => {
            if (item.closingActive) {
              searchArr.push(item);
            }
          });
          programTableData.length = 0;
          programTableData.push(...searchArr);
        } else {
          programTableData.forEach((item: any) => {
            if (!item.closingActive) {
              searchArr.push(item);
            }
          });
          programTableData.length = 0;
          programTableData.push(...searchArr);
        }
      }
    };
    // 表格列筛选选中情况小窗事件
    const hide = (params: number) => {
      filterSelArr();
      filterActive.value = params;
      if (params == 0 || params == 1) {
        filterPActive(params);
      }
      visible.value = false;
    };
    // bu列数据筛选后展示的表格数据结果数组
    const buFilter = (param: string) => {
      if (param == "All") {
        getTable();
      } else {
        if (programTableData.length) {
          searchArr.length = 0;
          programTableData.forEach((ele: any) => {
            if (param == ele.bu) {
              searchArr.push(ele);
            }
          });
          programTableData.length = 0;
          programTableData.push(...searchArr);
        }
      }
    };
    // 表格bu列筛选选中情况小窗事件
    const hidebu = (item: string) => {
      filterSelArr();
      filterBu.value = item;
      buFilter(item);
      buVisible.value = false;
    };
    // 重置按钮事件
    const reset = () => {
      pCodeArr.value = "";
      pNameArr.value = "";
      statusVal.value = undefined; //外放清空
      filterActive.value = 2; //默认表格行数据选中全部
      filterBu.value = "All"; //默认选中BU列为空
      getTable();
    };
    const saveBtn = () => {
      saveTableInfo(programTableData).finally(() => {
        context.emit("refresh-table"); //刷新上面表格更新计算结果
      });
    };
    const handleExport = () => {
      const params = {
        url: `/pcapi/forecast/program/exportForecastProgramInfo`,
        params: {
          mcId: props.makeId
        }
      };
      blobDownload(params);
    };
    return {
      tableHeight,
      showPrompt,
      changeSel,
      programTable,
      programTableData,
      programTableDataTemp,
      selectedRowKeys,
      onSelectChange,
      visible,
      hide,
      filterActive,
      filterBu,
      pStatusArr,
      pCodeArr,
      pNameArr,
      filterSelArr,
      reset,
      saveBtn,
      statusVal,
      buVisible,
      hidebu,
      buTypeArr,
      handleExport
    };
  }
});
