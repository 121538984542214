
import { defineComponent, ref, computed } from "vue";
import { message } from "ant-design-vue";
import { getTopic } from "@/API/closing";
import Decimal from '@/utils/closing/decimal';
import {
  getInterestInfo as getInterestInfoAPI,
  saveInterestInfo as saveInterestInfoAPI,
} from "@/API/forecast";
import blobDownload from "@/utils/blobDownload";
export default defineComponent({
  props: {
    make: {
      type: String,
      default: "",
    },
    closingId: {
      type: Number,
      default: 0,
    },
    makeYear: {
      type: String,
      default: "",
    },
    makeMonth: {
      type: String,
      default: "",
    },
  },
  emits: ["refresh-table"],
  setup(props, ctx) {
    const monthes = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    const columns = ref<any>([
      { title: "", dataIndex: "name", width: "140px", align: "left" },
      {
        title: `${props.makeYear}-01`,
        dataIndex: `01`,
        slots: { customRender: "01" },
        align: "right",
      },
      {
        title: `${props.makeYear}-02`,
        dataIndex: `02`,
        slots: { customRender: "02" },
        align: "right",
      },
      {
        title: `${props.makeYear}-03`,
        dataIndex: `03`,
        slots: { customRender: "03" },
        align: "right",
      },
      {
        title: `${props.makeYear}-04`,
        dataIndex: `04`,
        slots: { customRender: "04" },
        align: "right",
      },
      {
        title: `${props.makeYear}-05`,
        dataIndex: `05`,
        slots: { customRender: "05" },
        align: "right",
      },
      {
        title: `${props.makeYear}-06`,
        dataIndex: `06`,
        slots: { customRender: "06" },
        align: "right",
      },
      {
        title: `${props.makeYear}-07`,
        dataIndex: `07`,
        slots: { customRender: "07" },
        align: "right",
      },
      {
        title: `${props.makeYear}-08`,
        dataIndex: `08`,
        slots: { customRender: "08" },
        align: "right",
      },
      {
        title: `${props.makeYear}-09`,
        dataIndex: `09`,
        slots: { customRender: "09" },
        align: "right",
      },
      {
        title: `${props.makeYear}-10`,
        dataIndex: `10`,
        slots: { customRender: "10" },
        align: "right",
      },
      {
        title: `${props.makeYear}-11`,
        dataIndex: `11`,
        slots: { customRender: "11" },
        align: "right",
      },
      {
        title: `${props.makeYear}-12`,
        dataIndex: `12`,
        slots: { customRender: "12" },
        align: "right",
      },
      {
        title: `${props.makeYear}-FY`,
        dataIndex: `total`,
        align: "right",
      },
    ]);
    const data = ref<any>([
       {
        key: 0,
        name: "DPP/LLP",
      },
      {
        key: 1,
        name: "Days on Floor Plan",
      },
      {
        key: 2,
        name: "Tax Rate",
      },
      {
        key: 3,
        name: "Penetration%",
      },
      {
        key: 4,
        name: "WS BP Interest Rate",
      },
     
      {
        key: 5,
        name: "Floor Plan SD",
      },
      {
        key: 6,
        name: "Floor Plan SD%",
      },
    ]);
    const indexTokey = {
      0: "dppLlp",
      1: "daysOnFloorPlan",
      2: "taxRate",
      3: "penerationPercent",
      4: "wsbpinterestRate",
      
      5: "floorPlanSd",
      6: "floorPlanSdPercent",
    };
    // table 数据转换
    const setupData = (tableData: any[]) => {
      data.value.forEach((item: any, index: number) => {
        monthes.forEach(month => item.month = '')
        tableData.forEach((tableItem) => {
          if (tableItem.month === 'total' && index < 5) {
            item[tableItem.month] = ''
          } else if (index === 5) {
            item[tableItem.month] = new Decimal(tableItem[indexTokey[index]]).round(0)
          } else if (index === 1) {
            item[tableItem.month] = new Decimal(tableItem[indexTokey[index]]).fixed(0)
          } else  {
            item[tableItem.month] = tableItem.month === props.makeMonth && index < 5 ? tableItem[indexTokey[index]].replace(/[,]/g, '') : tableItem[indexTokey[index]]
          }
        })
        // monthes.forEach((month) => {
        //   if (month === tableData[0].month) {
        //     if (indexTokey[index] == "daysOnFloorPlan") {
        //       let tempTotal = tableData[0][indexTokey[index]]
        //         ? Math.round(
        //             tableData[0][indexTokey[index]].replace(/,/g, "") * 1
        //           )
        //         : "";
        //       tempTotal = tempTotal
        //         .toString()
        //         .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
        //         console.log(tempTotal)
        //       tableData[0][indexTokey[index]] = tempTotal;
        //     }

        //     item[month] = tableData[0][indexTokey[index]];
        //   } else {
        //     item[month] = "";
        //   }
        // });
     
        // if (indexTokey[index]=='floorPlanSd'||indexTokey[index]=='floorPlanSdPercent'){
        //    item.total = tableData[1][indexTokey[index]];
        // }
        // if (item.name=='Floor Plan SD'){
        
        //   item['10']=parseInt(item['10'].replace(/,/g,'')).toLocaleString() ;
        //   item['total']=parseInt(item['total'].replace(/,/g,'')).toLocaleString() ;
        // }
      });
     
    };

    // 保留2位小数
    const saveTwo = (value: string, withPercent = false) => {
      let str;
      str = value.replace(/[^\-\d.]/g, "");
      if (str) {
        str = str.replace(/,/g, "");
        const hasPot = str.indexOf(".");
        if (hasPot > -1) {
          const leftNum = str.substring(0, hasPot);
          let rightNum = str.substring(hasPot + 1, str.length);
          if (rightNum.length > 15) {
            rightNum = str.substring(hasPot + 1, hasPot + 3);
          }
          str = leftNum + "." + rightNum;
        }
        return withPercent ? str + "%" : str;
      } else {
        return "";
      }
    };

    // 修改框添加千分符并只保留15位小数
    const inputAddMicrometer = (amountVAT: string) => {
      let str;
      str = amountVAT.replace(/[^\-\d.]/g, "");
      if (str) {
        str = str.replace(/,/g, "");
        const hasPot = str.indexOf(".");
        if (hasPot > -1) {
          let leftNum = str.substring(0, hasPot);
          let rightNum = str.substring(hasPot + 1, str.length);
          leftNum = leftNum.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
          if (rightNum.length > 15) {
            rightNum = str.substring(hasPot + 1, hasPot + 16);
          }
          str = leftNum + "." + rightNum;
        } else {
          str = str.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
        }
        return str;
      } else {
        return "";
      }
    };

    const initInfoData = () => {
      getInterestInfoAPI({
        mcId: props.closingId,
      }).then((res: any) => {
        setupData(res);
      });
    };
    initInfoData();

    // const enableSave = computed(() => {
    //   // return inputData.value[InputTableRowTitle.FloorPlan][props.makeMonth].amount !== '' &&
    //   //   // inputData.value[InputTableRowTitle.PMT][props.makeMonth].amount !== '' &&
    //   //   inputData.value[InputTableRowTitle.WsBpInterestRate][props.makeMonth].amount !== ''
    //   return;
    // });

    const handleSave = () => {
      const params = {
        daysOnFloorPlan: 0,
        taxRate: 0,
        mcId: props.closingId,
        penerationPercent: 0,
        wsbpinterestRate: 0,
      };
      data.value.forEach((item: any, index: number) => {
        params[indexTokey[index]] = Number(
          item[props.makeMonth].replace(/[^\-\d.]/g, "")
        );
      });
      saveInterestInfoAPI(params)
        .then(() => {
          initInfoData();
        })
        .then(() => {
          ctx.emit("refresh-table");
          message.success("Save successfully!");
        });
    };
    // INPUT 文字提示
    const inputText = ref("");
    getTopic({ page: "INTEREST" }).then((res) => {
      inputText.value = res;
    });
    const handleExport = () => {
      const params = {
        url: `/pcapi/forecast/interest/exportForecastInterestInfo`,
        params: {
          mcId: props.closingId,
        },
      };
      blobDownload(params);
      
    };

    const handleInput = (record: any, month: string) => {
      if (record.key === 1) {
        record[month] = new Decimal(record[month]).fixed(0, false)
      } else {
        record[month] = saveTwo(record[month])
      }
    }

    return {
      // enableSave,
      handleSave,
      inputText,
      monthes,
      columns,
      data,
      handleExport,
      handleInput
    };
  },
});
