
import { defineComponent, ref, reactive } from "vue";
import { getOverview } from "@/API/forecast";
import {  dealerStockAccess } from "@/API/closing";
import BlueInvoice from "@/views/Forecast/Tab/Components/BlueInvoice.vue";
import programBase from "@/views/Forecast/Tab/Components/ProgramBase.vue";
import SpecialAdjustment from "@/views/Forecast/Tab/Components/SpecialAdjustment.vue";
import Release from "@/views/Forecast/Tab/Components/Release.vue";
import Interest from "@/views/Forecast/Tab/Components/Interest.vue";
import DealerStock from "@/views/Forecast/Tab/Components/DealerStock.vue";
import { message } from "ant-design-vue";
type TableDataType = {
  name: string;
  value: string;
};
export default defineComponent({
  components: {
    BlueInvoice,
    programBase,
    Interest,
    SpecialAdjustment,
    Release,
    DealerStock
  },
  props: {
    make: {
      type: String,
      default: ""
    },
    makeId: {
      type: Number,
      required: true,
      default: 1
    },
    makeYear: {
      type: String,
      default: ""
    },
    makeMonth: {
      type: String,
      default: ""
    },
    activeName: {
      type: String,
      default: ""
    },
     breadcrumbName: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const blueInvoice = ref();
    const programBase = ref();
    const searchMarterial = ref(null);
    const searchVehicle = ref(null);
    const enumTitle = {
      "01": "MTD Jan",
      "02": "MTD Feb",
      "03": "MTD Mar",
      "04": "MTD Apr",
      "05": "MTD May",
      "06": "MTD Jun",
      "07": "MTD Jul",
      "08": "MTD Aug",
      "09": "MTD Sep",
      "10": "MTD Oct",
      "11": "MTD Nov",
      "12": "MTD Dec"
    };
    // 搜索结果表格
    const columnSearch = reactive([
      {
        title: "MTD Jun",
        dataIndex: "MaterialSales",
        key: "MaterialSales",
        align: "right"
      },
      {
        title: "Vehicle Model",
        dataIndex: "VehicleModel",
        key: "VehicleModel",
        align: "right"
      }
    ]);
    const oneTable = reactive<TableDataType[]>([
      { name: "", value: "" },
      { name: "", value: "" }
    ]);
    const oneTitle = enumTitle[props.makeMonth];
    const twoTable = reactive<TableDataType[]>([
      { name: "", value: "" },
      { name: "", value: "" }
    ]);
    const dataSearch = reactive([
      {
        VehicleModel: "E260 style",
        MaterialSales: "21308010-CN1"
      },
      {
        VehicleModel: "E260 style",
        MaterialSales: "21308010-CN2"
      }
    ]);
    const columnSearch2 = reactive([
      {
        dataIndex: "cr",
        key: "cr",
        width: "116px",
        align: "right",
        slots: { title: "crTitle", customRender: "cr" }
      },
      {
        dataIndex: "fleetUc",
        key: "fleetUc",
        width: "116px",
        align: "right",
        slots: { title: "fleetUcTitle", customRender: "fleetUc" }
      },
      {
        dataIndex: "interest",
        key: "interest",
        width: "140px",
        align: "right",
        slots: { title: "interestTitle", customRender: "interest" }
      },
      {
        dataIndex: "blueInvoice",
        key: "blueInvoice",
        width: "140px",
        align: "right",
        slots: { title: "customTitle", customRender: "blueInvoice" }
      },
      {
        dataIndex: "dlrStockAdj",
        key: "dlrStockAdj",
        align: "right",
        width: "110px",
        slots: { title: "dlrStockAdjTitle", customRender: "dlrStockAdj" }
      },
      {
        dataIndex: "specialAdj",
        key: "specialAdj",
        width: "120px",
        align: "right",
        slots: { title: "specialTitle", customRender: "specialAdj" }
      },
      {
        dataIndex: "release",
        key: "release",
        width: "120px",
        align: "right",
        slots: { title: "releaseTitle", customRender: "release" }
      }
    ]);
    const dataSearch2 = reactive<unknown[]>([]);

    // 其它卡片临时隐藏
    const caterageryCard = reactive([
      {
        name: "Program Base Accrual",
        id: 0
      },
      {
        name: "Blue Invoice",
        id: 1
      },
      {
        name: "MBAFC Interest",
        id: 2
      },
      {
        name: "Dealer Stock Adj.(DS Adj.)",
        id: 3
      },
      {
        name: "Special Adj.",
        id: 4
      },
      {
        name: "Release",
        id: 5
      }
    ]);
    const isActive = ref(0);
    
    // 是否从Final View 跳过来
    props.activeName === "blueInvoice"
      ? (isActive.value = 1)
      : (isActive.value = 0);
    // const selectCard = (id: number) => (isActive.value = id);
     const selectCard = (id: number) => {
      if (id === 3) {
        dealerStockAccess({ mcId: props.makeId == -1 ? 1 : props.makeId })
          .then((res) => {
            console.log(3931, res);
            if (res.message === "success") {
              isActive.value = id;
            } else {
              message.error(
                "There is no Dealer Stock business in current month"
              );
            }
          })
          // .catch((err) => {
          //   caterageryCard.value = caterageryCard.value.filter((item) => {
          //     return item.id != 3;
          //   });
          // });
        // console.log("Dealer Stock Adj.(DS Adj.)")
      } else {
        isActive.value = id;
      }
    };
    // 初始化overview表格信息
    const initOverview = () => {
      getOverview({ mcId: props.makeId }).then(res => {
        oneTable.length = 0;
        dataSearch2.length = 0;
        twoTable.length = 0;
        oneTable.push(...res.table1);
        dataSearch2.push(...res.table3);
        twoTable.push(...res.table2);
      });
    };
    initOverview();
    const refreshOverview = () => {
      initOverview();
    };
    return {
      searchMarterial,
      searchVehicle,
      columnSearch,
      dataSearch,
      oneTable,
      oneTitle,
      twoTable,
      dataSearch2,
      columnSearch2,
      caterageryCard,
      selectCard,
      isActive,
      initOverview,
      refreshOverview,
      blueInvoice,
      programBase
    };
  }
});
