
import { defineComponent, nextTick, ref, watch } from "vue";
import Basic from "@/views/Forecast/Tab/BasicSetting.vue";
import DataPreparation from "@/views/Forecast/Tab/DataPreparation.vue";
import GenerateForecast from "@/views/Forecast/Tab/GenerateForecast.vue";
import FinalView from "@/views/Forecast/Tab/FinalView.vue";
enum TabType {
  BasicSetting,
  DataPreparation,
  GenerateClosing,
  FinalView
}
export default defineComponent({
  components: {
    Basic,
    DataPreparation,
    GenerateForecast,
    FinalView
  },
  setup() {
    const activeKey = ref<string>("BasicSetting");
    // 是否跳转到Blue Invoice
    const activeName = ref<string>("");
    const cbVersion = ref("");
    const basicSetting = ref();
    const dataPreparation = ref();
    const finalView = ref();
    const make = ref<string>("");
    const makeYear = ref<string>("");
    const makeMonth = ref<string>("");
    const generateForecast = ref();
    const breadcrumbVersion = ref("");
    // 接收传入的ID
    const makeId = ref<number>();
    const breadcrumbName = ref("Homepage");
    const handleChangeTab = () => {
      activeKey.value === "BasicSetting" && basicSetting.value.initData();
      if (activeKey.value === "BasicSetting") {
        breadcrumbName.value = "Homepage";
      }

      nextTick(() => {
        activeKey.value === "DataPreparation" &&
          dataPreparation.value.initNew();
        activeKey.value === "FinalView" && finalView.value.initState();
      });
    };
    watch(
      () => activeKey.value,
      () => {
        activeKey.value !== "DataPreparation" &&
          dataPreparation.value?.timer &&
          clearInterval(dataPreparation.value.timer);
      }
    );
    const tabChangEvent = (
      tab: string,
      closingMake = "",
      version = "",
      id = -1,
      month = "",
      year = "",
      jumpName = ""
    ) => {
      activeKey.value = tab;
      make.value = closingMake;
      makeId.value = id;
      makeYear.value = year;
      makeMonth.value = month;
      if (tab === "BasicSetting") {
        breadcrumbName.value = "Homepage";
      } else {
        breadcrumbName.value = `${closingMake} > ${version}`;
      }
      cbVersion.value = version;
      breadcrumbVersion.value = version;
      activeName.value = jumpName;
      nextTick(() => {
        tab === "DataPreparation" && dataPreparation.value.initNew();
        tab === "FinalView" && finalView.value.initState();
        tab === "GenerateForecast" &&
          jumpName !== "blueInvoice" &&
          generateForecast.value.programBase.reset();
      });
    };
    const changeMakeId = (id = -1) => {
      makeId.value = id;
    };
    const isfinal = ref(true);
  const  changeFinal=(value: any)=>{
isfinal.value=value;
  }
    return {
      changeFinal,
      isfinal,
      activeKey,
      activeName,
      tabChangEvent,
      changeMakeId,
      make,
      breadcrumbName,
      makeId,
      basicSetting,
      dataPreparation,
      handleChangeTab,
      makeMonth,
      makeYear,
      breadcrumbVersion,
      generateForecast,
      finalView,
      cbVersion,
      TabType
    };
  }
});
