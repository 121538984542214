
import { defineComponent, ref } from "vue";
import {
  getCarculation,
  saveCalculation,
  getAllocationLeft,
  getAllocationRight,
  saveAllocation,
  resetAvg
} from "@/API/closing";
import blobDownload from "@/utils/blobDownload";
import PlanningDataSelector from '@/views/Closing/Tab/Components/PlanningDataSelector.vue'
export default defineComponent({
  props: {
    makeId: {
      type: Number,
      required: true,
    },
    make: {
      type: String,
      required: true
    }
  },
  components: {
    PlanningDataSelector
  },
  emits: [
    'init-overview'
  ],
  setup(props, { emit }) {
    const activeKey = ref('Calculation');
    const saveType = ref('');
    const tableHeight = window.innerHeight - 440;
    const tableWidth = window.innerWidth;
    const dsabtn = ref<any>(true);
    const ambtn = ref<any>(true);
    const whitSpaceColumns = ref([
      {
        title: "",
        dataIndex: "w1",
        width: "80px",
        key: "make",
      },
      {
        title: "",
        dataIndex: "w2",
        width: "100px",
        key: "brand",
      },
      {
        title: "",
        dataIndex: "w3",
        width: "100px",
        key: "typeClass",
      },
      {
        title: "",
        dataIndex: "w4",
        width: "150px",
        key: "model",
        align: "right",
        slots: { customRender: "time" },
      },
    ]);
    const whitSpacedata = ref<any>([
      {
        w1: "",
        w2: "",
        w3: "",
        w4: "",
      },
    ]);
    const calculationColumns = ref([
      {
        title: "Type Class",
        dataIndex: "typeClass",
        width: "70px",
        key: "brand",
        align: "left",
        fixed: "left",
      },
      {
        title: "Model",
        dataIndex: "model",
        width: "350px",
        key: "typeClass",
        align: "left",
        fixed: "left",
      },
      {
        title: "Brand",
        dataIndex: "brand",
        width: "70px",
        key: "make",
        align: "left",
      },
      {
        dataIndex: "dealerStockAdjustment",
        width: "160px",
        key: "model",
        align: "right",
        slots: { title: "dealerStockAdjustment" },
      },
      {
        dataIndex: "targetAvgSI",
        width: "100px",
        key: "avgSi",
        align: "right",
        className: "tab1-blue",
        slots: { title: "targetAvgSi" },
      },
      {
        title: "Opening balance",
        dataIndex: "openingBalance",
        width: "150px",
        key: "avgSi",
        align: "right",
      },
      {
        dataIndex: "accrualMovement",
        width: "150px",
        key: "avgSi",
        align: "right",
        slots: { title: "accrualMovement" },
      },
    ]);
    const calculationdata = ref<any>([]);
    const allocationColumns1 = ref([
      {
        dataIndex: "operation",
        key: "operation",
        width: 30,
        slots: { customRender: "checked" },
        fixed: "left",
      },
      {
        title: "Type Class",
        dataIndex: "typeClass",
        width: 70,
        key: "brand",
        align: "left",
        fixed: "left"
      },
      {
        title: "Model",
        dataIndex: "model",
        width: 350,
        key: "typeClass",
        align: "left",
        fixed: "left",
      },

      {
        title: "Brand",
        dataIndex: "brand",
        width: 70,
        key: "make",
        align: "left",
      },
      {
        title: "WS LLP",
        dataIndex: "wsLLp",
        width: 110,
        key: "model",
        align: "right",
      },
      {
        title: "SD%",
        dataIndex: "sdPercent",
        width: 80,
        key: "volume",
        align: "right",
      },
      {
        title: "Dealer Stock Adjustment",
        dataIndex: "dealerStockAdjustment",
        width: 120,
        key: "llp",
        align: "right",
      },
    ]);
    const allocationdata1 = ref<any>([]);
    const allocationColumns2 = ref([
      {
        title: "Type Class",
        dataIndex: "typeClass",
        width: "80px",
        key: "typeClass",
        align: "left",
        fixed: "left"
      },
      {
        title: "Model",
        dataIndex: "model",
        width: "300px",
        key: "model",
        align: "left",
        fixed: "left",
      },
      {
        title: "Brand",
        dataIndex: "brand",
        width: "70px",
        key: "brand",
        align: "left",
      },
      {
        dataIndex: "totalAllocationAmount",
        width: 160,
        key: "totalAllocationAmount",
        align: "right",
        slots: { title: "totalAllocationAmount" },
      },
    ]);
    const allocationdata2 = ref<any>([]);
    const calculationQuarter = ref<any>("");
    const caluInput = ref<any>([]);
    const caluInputBack = ref<any>([]);
    const changeChecked = (record: any, index: any) => {
      if (index === 0) {
        let notall = true;
        allocationdata1.value.forEach((allcoai: any, allcoaind: any) => {
          if (allcoai.selectFlag === false && allcoaind != 0) {
            notall = false;
          }
        });
        if (notall) {
          allocationdata1.value.forEach((allcoai: any, allcoaind: any) => {
            allcoai.selectFlag = false;
          });
        } else {
          allocationdata1.value.forEach((allcoai: any, allcoaind: any) => {
            allcoai.selectFlag = true;
          });
        }
      } else {
        let all = true;
        allocationdata1.value.forEach((allcoai: any, allcoaind: any) => {
          if (allcoai.selectFlag === false && allcoaind != 0) {
            all = false;
          }
        });
        if (all) {
          allocationdata1.value[0].selectFlag = true;
        } else {
          allocationdata1.value[0].selectFlag = false;
        }
      }
    };
    const doGetCalculation = () => {
      /*getCarculation({mcId: props.makeId}).then((res)=>{
              console.log(369,res)
              calculationQuarter.value= res.quarter
          })*/
      getCarculation({ mcId: props.makeId }).then((res) => {
        calculationQuarter.value = res.quarter;
        const dataArr = ref<any>([]);
        // dataArr.value.push(res.contentMake)
        const makeobj = {};
        res.contentMake.ext.forEach((makei: any, makeind: any) => {
          makeobj[makei.keyName] = makei.value;
        });
        makeobj["id"] = res.contentMake.id;
        dataArr.value.push(makeobj);
        res.contentImpact.forEach((impacti: any, impctind: any) => {
          const contentobj = {};
          impacti.ext.forEach((i: any, ind: any) => {
            contentobj[i.keyName] = i.value;
          });
          // contentobj["id"] = impacti.id;
          dataArr.value.push(contentobj);
        });
        dataArr.value.forEach((dai: any, daind: any) => {
          if (dai.adjAvgSI == "0.00%") {
            dai.adjAvgSI = "";
          }
          caluInput.value[daind] = dai.adjAvgSI;
          caluInputBack.value[daind] = dai.adjAvgSI;
        });
        calculationdata.value = dataArr.value;
      });
    };
    doGetCalculation();
    const doGetAllocationLeft = () => {
      getAllocationLeft({ mcId: props.makeId }).then((res) => {
        const dataArr = ref<any>([]);
        // dataArr.value.push(res.contentMake)
        const makeobj = {};
        res.contentMake.ext.forEach((makei: any, makeind: any) => {
          makeobj[makei.keyName] = makei.value;
        });
        makeobj["id"] = res.contentMake.id;
        dataArr.value.push(makeobj);
        res.contentImpact.forEach((impacti: any, impctind: any) => {
          const contentobj = {};
          impacti.ext.forEach((i: any, ind: any) => {
            contentobj[i.keyName] = i.value;
          });
          // contentobj["id"] = impacti.id;
          dataArr.value.push(contentobj);
        });
        allocationdata1.value = dataArr.value;
      });
    };
    let tempAllocationColumns2: any;
    let tempIndex: any;
    doGetAllocationLeft();
    const doGetAllocationRight = () => {
      getAllocationRight({ mcId: props.makeId }).then((res) => {
        const dataArr = ref<any>([]);
        // dataArr.value.push(res.contentMake)
        const makeobj = {};
        res.contentMake.ext.forEach((makei: any, makeind: any) => {
          makeobj[makei.keyName] = makei.value;
          if (
            makei.keyName.indexOf("Q1") != -1 ||
            makei.keyName.indexOf("Q2") != -1 ||
            makei.keyName.indexOf("Q3") != -1||
            makei.keyName.indexOf("Q4") != -1
          ) {
            let isrepeat = false;
            allocationColumns2.value.forEach((allcoi: any, cllcoind: any) => {
              // console.log(488,allcoi.title,makei.keyName)
              if (allcoi.title == makei.keyName) {
                isrepeat = true;
              }
            });
            if (!isrepeat) {
              allocationColumns2.value.push({
                title: makei.keyName,
                dataIndex: makei.keyName,
                width: "100px",
                key: makei.keyName,
                align: "right",
              });
            }
          }
        });
        makeobj["id"] = res.contentMake.id;
        dataArr.value.push(makeobj);
        res.contentImpact.forEach((impacti: any, impctind: any) => {
          const contentobj = {};
          impacti.ext.forEach((i: any, ind: any) => {
            contentobj[i.keyName] = i.value;
            // console.log(511,allocationColumns2.value)
            if (
              i.keyName.indexOf("Q1") != -1 ||
              i.keyName.indexOf("Q2") != -1 ||
              i.keyName.indexOf("Q3") != -1||
              i.keyName.indexOf("Q4") != -1
            ) {
              let isrepeat = false;
              allocationColumns2.value.forEach((allcoi: any, cllcoind: any) => {
                // console.log(488,allcoi.title,i.keyName)
                if (allcoi.title == i.keyName) {
                  isrepeat = true;
                }
              });
              if (!isrepeat) {
                allocationColumns2.value.push({
                  title: i.keyName,
                  dataIndex: i.keyName,
                  width: "100px",
                  key: i.keyName,
                  align: "right",
                });
              }
            }
          });
          contentobj["id"] = impacti.id;
          dataArr.value.push(contentobj);
        });
        tempAllocationColumns2 = [...allocationColumns2.value];

        allocationColumns2.value.forEach((item: any, index: any) => {
          if (item.dataIndex == "totalAllocationAmount") {
            tempIndex = index;
          }
        });
        allocationColumns2.value = allocationColumns2.value.slice(
          0,
          tempIndex + 1
        );
        allocationdata2.value = dataArr.value;
      });
    };
    doGetAllocationRight();
    const handleSaveCalculation = (e: any) => {
      e.target.blur();
      saveType.value='';
      const params = {
        mcId: props.makeId,
        dtoList: [],
      };
      const dtolistArr = ref<any>([]);
      calculationdata.value.forEach((datai: any, dataind: any) => {
        if (dataind > 0) {
          const dataobj = {
            id: "",
            avg: "",
          };
          dataobj.id = datai.id ? datai.id : "";
          dataobj.avg = caluInputBack.value[dataind]
            ? caluInputBack.value[dataind]
            : "0.00%";
          dtolistArr.value.push(dataobj);
        }
      });
      params.dtoList = dtolistArr.value;
      saveCalculation(params).then((res) => {
        doGetCalculation();
        emit('init-overview');
      });
    };
    const handleSaveAllocation = () => {
      const params = {
        mcId: props.makeId,
        ids: [],
        allocaationAmount: allocationdata1.value[0].dealerStockAdjustment,
      };
      const ids = ref<any>([]);
      allocationdata1.value.forEach((allcoai: any, allcoaind: any) => {
        if (allcoaind != 0 && allcoai.selectFlag === true) {
          ids.value.push(allcoai.id ? allcoai.id : "");
        }
      });
      params.ids = ids.value;
      saveAllocation(params).then((res) => {
        doGetAllocationLeft();
        emit('init-overview');
      });
    };
    const handleClearAllocation = () => {
      const params = {
        mcId: props.makeId,
        ids: [],
        allocaationAmount: allocationdata1.value[0].dealerStockAdjustment,
      };
      saveAllocation(params).then((res) => {
        doGetAllocationLeft();
        emit('init-overview');
      });
    };
    const dsOpen = () => {
      dsabtn.value = false;
      const addColumns = [
        {
          title: "Adj. Avg.SI%",
          dataIndex: "adjAvgSI",
          width: "120px",
          key: "avgSi",
          align: "right",
          slots: { customRender: "adjAvgSI" },
        },
        {
          title: "Avg.SI%",
          dataIndex: "avgSI",
          width: "100px",
          key: "avgSi",
          align: "right",
        },
        {
          title: "DLR LLP",
          dataIndex: "dlrLlp",
          width: "100px",
          key: "avgSi",
          align: "right",
        },
        {
          title: "DLR Volume",
          dataIndex: "dlrVolume",
          width: "100px",
          key: "llp",
          align: "right",
        },
        {
          title: "Dealer stock target",
          dataIndex: "dealerStockTarget",
          width: "150px",
          key: "volume",
          align: "right",
        },
      ];
      addColumns.forEach((i: any, ind: any) => {
        calculationColumns.value.splice(4, 0, i);
      });
    };
    const dsClose = () => {
      dsabtn.value = true;
      const addColumns = [
        {
          title: "Adj. Avg.SI%",
          dataIndex: "adjAvgSI",
          width: "120px",
          key: "avgSi",
          align: "right",
          slots: { customRender: "adjAvgSI" },
        },
        {
          title: "Avg.SI%",
          dataIndex: "avgSI",
          width: "100px",
          key: "avgSi",
          align: "right",
        },
        {
          title: "DLR LLP",
          dataIndex: "dlrLlp",
          width: "100px",
          key: "avgSi",
          align: "right",
        },
        {
          title: "DLR Volume",
          dataIndex: "dlrVolume",
          width: "100px",
          key: "llp",
          align: "right",
        },
        {
          title: "Dealer stock target",
          dataIndex: "dealerStockTarget",
          width: "150px",
          key: "volume",
          align: "right",
        },
      ];
      addColumns.forEach((i: any, ind: any) => {
        calculationColumns.value.forEach((alli: any, allind: any) => {
          if (alli.dataIndex === i.dataIndex) {
            calculationColumns.value.splice(allind, 1);
          }
        });
      });
    };
    const amOpen = () => {
      ambtn.value = false;
      const addColumns = [
        {
          title: "Accrual amount_WS base",
          dataIndex: "accrualAmountWSbase",
          width: "150px",
          key: "avgSi",
          align: "right",
        },
        {
          title: "Accrual amount_RT base",
          dataIndex: "accrualAmountRtbase",
          width: "150px",
          key: "avgSi",
          align: "right",
        },
      ];
      addColumns.forEach((i: any, ind: any) => {
        calculationColumns.value.push(i);
      });
    };
    const amClose = () => {
      ambtn.value = true;
      const addColumns = [
        {
          title: "Accrual amount_WS base",
          dataIndex: "accrualAmountWSbase",
          width: "150px",
          key: "avgSi",
          align: "right",
        },
        {
          title: "Accrual amount_RT base",
          dataIndex: "accrualAmountRtbase",
          width: "150px",
          key: "avgSi",
          align: "right",
        },
      ];
      addColumns.forEach((i: any, ind: any) => {
        calculationColumns.value.forEach((alli: any, allind: any) => {
          if (alli.dataIndex === i.dataIndex) {
            calculationColumns.value.splice(allind, 1);
          }
        });
      });
    };
    const handleExport = () => {
      const params = {
        url: `/pcapi/api/v1/dealerStockAllocation/exportDSForTab1`,
        params: {
          mcId: props.makeId,
        },
      };
      blobDownload(params);
    };
      let tempBlur = true;
    const leave = (e: any) => {
            tempBlur = true;
      e.target.blur();
    };
    const numberSubWithPrecision = (n: string | number, p: number) => {
      if (typeof n === "number") {
        n = n.toString();
      }
      let i = n.search(/^\.\d/);
      if (i !== -1) {
        n = n.slice(0, i);
      }
      i = n.indexOf(".");
      if (i === -1) {
        return n;
      } else {
        return `${n.slice(0, i)}.${n.slice(i + 1, i + 1 + p)}`;
      }
    };
    const inputChange = (index: number) => {
      saveType.value = 'primary'
      let value = numberSubWithPrecision(caluInput.value[index].replace(/[^\d.-]/g, ''), 15)
      if (value === '') {
        value = ''
      } else {
        value = `${value}%`
      }
      caluInput.value[index] = value
      caluInputBack.value[index] = value
    };
    const itemInput: any = [];
    const inputIndex = (item: any) => {
      if (item) {
        itemInput.push(item);
      }
    };
  
    const inputBlur = (index: number) => {
      if (tempBlur) {
        itemInput.forEach((item: any, indexs: any) => {
          if (index == indexs) {
            itemInput[indexs].focus();
          }
        });
        
      }
      if (caluInput.value[index] === '' || caluInput.value[index] === '%') {
        caluInput.value[index] = ''
      } else {
        caluInput.value[index] =
          (
            caluInput.value[index].substring(
              0,
              caluInput.value[index].indexOf("%")
            ) * 1
          ).toFixed(2) + "%";
      }
      tempBlur=false;
    };
    const getInit = (activeKeys: any) => {
      if (activeKeys == "Allocation") {
        doGetAllocationRight();
        doGetAllocationLeft();
      }
    };
    const rowClick = (record: any, index: any): { onClick: Function } => {
      return {
        onClick: (e: any) => {
          //点击事件走失焦事件的时候不获取下一行焦点 回车时解开变量
          tempBlur = false;
          itemInput.forEach((item: any, indexs: any) => {
            if (index - 1 == indexs) {
              itemInput[indexs].focus();
            }
          });
          caluInput.value[index]= caluInputBack.value[index]
        },
      };
    };

    const totalbtn = ref<any>(true);
    const totalOpen = () => {
      totalbtn.value = false;
      allocationColumns2.value = tempAllocationColumns2;
    };
    const totalClose = () => {
      totalbtn.value = true;

      allocationColumns2.value = allocationColumns2.value.slice(
        0,
        tempIndex + 1
      );
    };

    const handleResetAvg = async (planningId: number) => {
      await resetAvg(props.makeId, planningId);
      if (activeKey.value == "Allocation") {
        doGetAllocationRight();
        doGetAllocationLeft();
      } else {
        doGetCalculation();
      }
      emit("init-overview");
    };

    return {
      saveType,
      rowClick,
      inputIndex,
      getInit,
      inputChange,
      inputBlur,
      leave,
      amClose,
      amOpen,
      dsClose,
      dsOpen,
      changeChecked,
      caluInput,
      whitSpacedata,
      whitSpaceColumns,
      calculationQuarter,
      doGetAllocationRight,
      doGetAllocationLeft,
      handleClearAllocation,
      handleSaveAllocation,
      handleSaveCalculation,
      doGetCalculation,
      ambtn,
      dsabtn,
      allocationdata2,
      allocationColumns2,
      allocationdata1,
      allocationColumns1,
      tableWidth,
      calculationdata,
      calculationColumns,
      activeKey,
      tableHeight,
      handleExport,
      totalbtn,
      totalOpen,
      totalClose,
      handleResetAvg
    };
  },
});
