
import { defineComponent, reactive, ref, toRefs,onBeforeUnmount } from "vue";
import {
  getDataPre,
  importData,
  getCalculate,
  initProgramInfo,
} from "@/API/forecast";
import downloadFile from "@/utils/blobDownload";
import store from '@/store';
export default defineComponent({
  emits: ["chang-tab"],
  props: {
    make: {
      default: "",
      type: String,
    },
    makeId: {
      type: Number,
      required: true,
    },
    makeYear: {
      default: "",
      type: String,
    },
    makeMonth: {
      default: "",
      type: String,
    },
    breadcrumbVersion: {
      default: "",
      type: String,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      kufriResult: "",
      kufriData: {
        resultMsg: "",
        importDate: "",
        result: "",
        ResultMsg: "",
        total: "",
      },
      programResult: "",
      programData: {
        resultMsg: "",
        ResultMsg: "",
        total: "",
      },
    });
    // const finalVersion = ref(false);
    // seachVersion({ mcId: props.makeId }).then(res => {
    //   finalVersion.value = res.finalVersion;
    // });
    // programs手动获取导入数据
    const initProgram = () => {
      initProgramInfo(props.makeId).catch(() => {
        state.programResult = "error";
        state.programData = {
          resultMsg: "Validate error",
          ResultMsg: "",
          total: "",
        };
      });
    };

    // 定时器
    const timer = ref<number | null>(null);
        const changeFirstLetter = (str: any) => {
      if (str) {
        //转换为字符串数组
        const array = str.toLowerCase().split(" ");
        for (let i = 0; i < array.length; i++) {
          //给数组的每一个元素重新赋值,将首字母变为大写
          array[i] =
            array[i][0].toUpperCase() + array[i].substring(1, array[i].length);
        }
        const string = array.join(" ");
        return string;
      }
    };
    const initData = () => {
         store.commit(
        'updateIsPolling',
        false
    );
      getDataPre({ mcId: props.makeId }).then((res) => {
          store.commit(
        'updateIsPolling',
        true
    );
        state.kufriData = {
          resultMsg: "",
          importDate: "",
          result: "",
          ResultMsg: "",
          total: "",
        };
        state.programData = {
          resultMsg: "",
          ResultMsg: "",
          total: "",
        };
        Object.assign(state.kufriData, res.forecastKufriData);
        console.log(state.kufriData.total)
        if (state.kufriData.total||state.kufriData.total=='0') {
          
          state.kufriData.ResultMsg = `${changeFirstLetter(
            state.kufriData.resultMsg
          )} ; ${state.kufriData.total
            .toString()
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")} Data Import`;
            
        }
        Object.assign(state.programData, res.forecastProgram);
        if (state.programData.total||state.programData.total=='0') {
          state.programData.ResultMsg = `${changeFirstLetter(
            state.programData.resultMsg
          )} ; ${state.programData.total
            .toString()
            .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")} Data Import`;
        }
       
        state.kufriResult = res.forecastKufriData?.result?.toLowerCase() || "";
        state.programResult = res.forecastProgram?.result?.toLowerCase() || "";
        // if (
        //   state.kufriResult !== "loading" &&
        //   state.programResult !== "loading"
        // ) {
        //   timer.value && clearInterval(timer.value);
        // }
      });
    };
    let isTemp=false;//是否第一次进入
     const initNew=()=>{
      if(isTemp){
        initData()
        timer.value = setInterval(() => {
          
        initData();
      }, 5000);
      }
      isTemp=true;
    }
    initNew();
    const handleImport = async (arg: string) => {
      // 定义变量是为了并发请求，无实际业务意义
      let resWs, init;
      // final版本 不允许点击
      // if (finalVersion.value) return;
      switch (arg) {
        case "all":
          // if (state.kufriResult !== "loading") {
            state.kufriResult = "loading";
            state.kufriData.resultMsg = "Processing";
            resWs = importData({ mcId: props.makeId });
          // }
          // if (state.programResult !== "loading") {
            state.programResult = "loading";
            state.programData.resultMsg = "Processing";
            init = initProgram();
          // }
          await resWs;
          await init;
          break;
        case "kufri":
          // if (state.kufriResult !== "loading") {
            state.kufriResult = "loading";
            state.kufriData.resultMsg = "Processing";
            await importData({ mcId: props.makeId });
          // }
          break;
        case "program": //添加programbase的初始化请求
          // if (state.programResult !== "loading") {
            state.programResult = "loading";
            state.programData.resultMsg = "Processing";
            await initProgram();
          // }
          break;
        default:
          break;
      }
      // timer.value = setInterval(() => {
      //   initData();
      // }, 5000);
    };

    const handleExport = (arg: string) => {
      const url = "/pcapi/closing/dataPreparation/export";
      downloadFile({ url, params: { type: arg, mcId: props.makeId } });
    };
    const handleUploadSuccess = () => {
      initData();
    };
    const handleCalculate = async () => {
      const res = await getCalculate({ mcId: props.makeId });
      res.code === "0" &&
        emit(
          "chang-tab",
          "GenerateForecast",
          props.make,
          props.breadcrumbVersion,
          props.makeId,
          props.makeMonth,
          props.makeYear
        );
    };
    onBeforeUnmount(()=>{
      clearInterval(timer.value as any);
    })
    return {
      ...toRefs(state),
      initData,
      handleExport,
      handleImport,
      handleUploadSuccess,
      handleCalculate,
      initProgramInfo,
      timer,
      initNew
    };
  },
});
