
import { defineComponent, nextTick, onUnmounted, ref, h } from "vue";
import { getBrandList, getReport3, getl1Structure } from "@/API/forecast";
import { ReportData, ExtData, TableHeader } from "@/views/Closing/type";
import useExpand from "@/hooks/closing/useExpand";
import deepClone from "@/hooks/closing/deepClone";
import blobDownload from "@/utils/blobDownload";
interface BrandType {
  id: string;
  isSelect: boolean;
}
const tableHeight = window.innerHeight - 450;
export default defineComponent({
  props: {
    makeId: {
      type: Number,
      required: true
    },
    makeYear: {
      type: String,
      default: ""
    },
    tdValue: {
      type: String,
      required: true
    },
    planningId: {
      type: Number,
      required: true
    },
    kufriVersion: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const brandArray = ref<BrandType[]>([]);
    // 单选L1的视角
    const l1 = ref("");
    const columns = ref<TableHeader[]>([]);
    const data = ref<ReportData[]>([]);

    const setColumnWidth = () => {
      const generate = (dataArray: TableHeader[]) => {
        dataArray.forEach((item: TableHeader) => {
          item.align = "center";
          if (item.children?.length > 0) {
            generate(item.children);
          } else {
            item.width = `${
              item.title.length * 9 > 65 ? item.title.length * 9 : 65
            }px`;
          }
        });
      };
      generate(columns.value);
      columns.value.forEach((item, index) => {
        item.width = index === 0 ? "350px" : item.width;
        // 只有 Program Based SI 支持表头横展
        if (index > 1 && l1.value === "Program Based SI") {
          item.title = h("div", { class: "table-header" }, item.title);
        }
      });
    };
    // 备份表头
    let columnsBackup: TableHeader[] = [];
    const radioArray = ref<string[]>([]);
    // expand to model 复选框初始化
    const checked = ref<boolean>(false);

    // 获取报表数据
    let brand = "";
    const getReportData = () => {
      brand = brandArray.value.reduce((brands: string, item: BrandType) => {
        if (item.isSelect) {
          brands = `${brands}${brands ? ',' : ''}${item.id}`
        }
        return brands
      }, '');
      getReport3({
        mcId: props.makeId,
        levelOne: l1.value,
        searchType: parseInt(props.tdValue),
        brand,
        planningId: props.planningId,
        kufriVersion: props.kufriVersion
      }).then(res => {
        checked.value = false;
        columnsBackup = [];
        columnsBackup = deepClone(res.head);
        columns.value = res.head;
        data.value = res.content;
        // 只有 Program Based SI 支持表头横展
        if (l1.value === "Program Based SI") {
          columns.value.forEach((item, index) => {
            if (index > 1) {
              item?.children?.splice(1);
            }
          });
        }
        setColumnWidth();
        let id = 0;
        const generate = (dataArray: ReportData[]) => {
          dataArray.forEach(item => {
            item.ext.forEach((ele: ExtData) => {
              item[ele.keyName] = ele.value;
              item.id = ++id;
            });
            if (item.children?.length > 0) {
              generate(item.children);
            } else {
              Reflect.deleteProperty(item, "children");
            }
          });
        };
        generate(data.value);
      });
    };

    const initData = async () => {
      const brandList = await getBrandList({ mcId: props.makeId });
      brandArray.value = [];
      brandList.forEach(item => {
        brandArray.value.push({ id: item, isSelect: true });
      });
      radioArray.value = await getl1Structure();
      l1.value = radioArray.value[0] ?? "";
      getReportData();
    };

    const selectBrand = (item: BrandType) => {
      item.isSelect = !item.isSelect;
      getReportData();
    };

    const expendTitle = (e: any) => {
      // 只有 Program Based SI 支持表头横展
      if (l1.value !== "Program Based SI") return;
      const text = e.target.outerText;
      columnsBackup.forEach((item, index) => {
        if (text === item.title && index > 1) {
          if (columns.value[index].children.length > 1) {
            columns.value[index]?.children?.splice(1);
          } else {
            columns.value[index].children = deepClone(item.children);
          }
        }
      });
      setColumnWidth();
    };

    let headerList: HTMLTableSectionElement | null;
    nextTick(() => {
      headerList = Array.from(
        (document.getElementById(
          "report3-table"
        ) as HTMLElement).getElementsByTagName("thead")
      )[0];
      headerList.addEventListener("click", expendTitle);
    });

    const { onExpand, expandedRowKeys } = useExpand(data, checked);

    onUnmounted(() => {
      (headerList as HTMLElement).removeEventListener("click", expendTitle);
      headerList = null;
    });
    const handleExport = () => {
      const params = {
        url: `/pcapi/forecast/reportData/exportOverviewByBrandAndL1`,
        params: {
          mcId: props.makeId,
          levelOne: l1.value,
          searchType: parseInt(props.tdValue),
          brand,
          planningId: props.planningId,
          kufriVersion: props.kufriVersion
        }
      };
      blobDownload(params);
    };
    return {
      data,
      columns,
      tableHeight,
      brandArray,
      selectBrand,
      expendTitle,
      l1,
      radioArray,
      getReportData,
      initData,
      onExpand,
      checked,
      expandedRowKeys,
      handleExport
    };
  }
});
