
import { defineComponent, ref, reactive, h } from "vue";
import {
  getCurrentYear,
  getMakeInfos,
  getLock,
  getNewMake
} from "@/API/forecast";
import moment from "moment";

type TargetTpye = {
  target: {
    innerText: string;
  };
};
type DataType = {
  name: string;
  header: any[];
  data: any[];
};
const monthSlots = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12"
];
export default defineComponent({
  emits: ["chang-tab",'change-final'],
  setup(_, { emit }) {
    const yearValue = ref("0000");
    let disableTime = "";
    const dataArray = reactive<DataType[]>([
      { name: "CBU", header: [], data: [] },
      { name: "PBP", header: [], data: [] },
      { name: "VAN", header: [], data: [] }
    ]);
    const handleTitle = (e: EventTarget | TargetTpye, arg: string) => {
      const [year, month] = (e as TargetTpye).target.innerText.split("-");
      let mcId: number | null;
      switch (arg) {
        case "CBU":
          mcId = dataArray[0].data[3][`${month}mcId`];
          break;
        case "PBP":
          mcId = dataArray[1].data[3][`${month}mcId`];
          break;
        case "VAN":
          mcId = dataArray[2].data[2][`${month}mcId`];
          break;
        default:
          mcId = null;
          break;
      }
      getNewMake({
        year,
        month,
        make: arg
      }).then(res => {
        emit('change-final',true)
        emit(
          "chang-tab",
          res.isFinal ? "FinalView" : "DataPreparation",
          arg,
          res.version,
          res.id,
          res.month,
          res.year
        );
      });
    };
    const handleCheckbox = async (record: any, month: string) => {
      try {
        await getLock({ mcId: record[`${month}mcId`], flag: record[month] });
      } catch (e) {
        record[month] = !record[month];
      }
    };
    /**
     * 设置单元格wip和final的icon
     */
    const setDataIcon = (flag: unknown) => {
      return !flag
        ? "font_family icon-fuxuankuang"
        : "font_family icon-fuxuankuang-yixuan-xianxing icontrue";
    };

    /**
     * 设置表头
     * year: 表头年
     * month: 表头月
     * make: CBU/PBP/VAN
     */
    const setHeader = (year: string, month: string, make: string) => {
      const flag = parseInt(disableTime) >= parseInt(`${year}${month}`);
      return flag
        ? h(
            "a",
            {
              onClick: ($event: EventTarget) => handleTitle($event, make)
            },
            `${year}-${month}`
          )
        : h(
            "span",
            {
              style: "color: gray"
            },
            `${year}-${month}`
          );
    };
    // 获取make数据
    const getMakeData = async () => {
      const resMake = await getMakeInfos({ year: yearValue.value });
      dataArray.forEach(item => {
        item.header = [
          {
            title: item.name,
            dataIndex: "name",
            width: "120px",
            align: "center"
          }
        ];
        item.data = [
          {
            key: "3",
            name: "Kufri"
          },
          {
            key: "1",
            name: "WIP Verison"
          },
          {
            key: "2",
            name: "Final Verison"
          },
          {
            key: "4",
            name: "Locked"
          }
        ];
        resMake[item.name].forEach((ele: any) => {
          const title = setHeader(ele.year, ele.month, item.name);
          item.header.push({
            title,
            align: "center",
            dataIndex: `${ele.month}`,
            slots: { customRender: `${ele.month}` }
          });
          item.data.forEach(el => {
            switch (el.key) {
              case "1":
                el[ele.month] = setDataIcon(ele.isWip);
                el.wip = ele.month;
                break;
              case "2":
                el[ele.month] = setDataIcon(ele.isFinal);
                el.final = ele.month;
                break;
              case "3":
                el[ele.month] = ele.kufri;
                break;
              case "4":
                el[ele.month] = ele.globalLock;
                el[ele.month + "mcId"] = ele.mcId;
                break;
              default:
                console.warn("error");
                break;
            }
          });
        });
      });
    };
    // 初始化数据
    const initData = async () => {
      const res = await getCurrentYear();
      yearValue.value = res.showYear.toString();
      disableTime = `${res.year}${String(res.month).padStart(2, "0")}`;
      getMakeData();
    };
    const openYear = ref(false);
    const handlerPanelChange = (value: string): void => {
      yearValue.value = moment(value).format("YYYY");
      openYear.value = false;
      getMakeData();
    };
    initData();
    return {
      yearValue,
      openYear,
      handlerPanelChange,
      handleCheckbox,
      initData,
      dataArray,
      monthSlots
    };
  }
});
