
import { defineComponent, ref, reactive } from "vue";
import { getTopic } from "@/API/closing";
import { getSubOverview, saveBlueInvoiceInfo } from "@/API/forecast";
import blobDownload from "@/utils/blobDownload";
export default defineComponent({
  props: {
    make: {
      type: String,
      default: ""
    },
    makeId: {
      type: Number,
      required: true
    },
    activeName: {
      type: String,
      default: ""
    },
    breadcrumbName:{
       type: String,
      default: ""
    }
  },
  setup(props, context) {
    const showPrompt = ref("");
    // 保存接口入参
    let saveParams: any[] = [];
    // 温馨提示
    const getTopicInfo = () => {
      getTopic({ page: "BLUE_INVOICE" }).then(res => {
        showPrompt.value = res;
      });
    };

    //获取提示信息
    getTopicInfo();

    const dataTable = reactive<any[]>([]);
    // 右侧表格
    let blueRightTable = [
      {
        title: "Demo Car",
        dataIndex: "demoCar",
        key: "2",
        align: "right",
        slots: { customRender: "demoCar" }
      },
      {
        title: "Bidding Car",
        dataIndex: "biddingCar",
        key: "3",
        align: "right",
        slots: { customRender: "biddingCar" }
      },
      {
        title: "Company Car",
        dataIndex: "companyCar",
        key: "4",
        align: "right",
        slots: { customRender: "companyCar" }
      },
      {
        title: "Oversea",
        dataIndex: "oversea",
        key: "5",
        align: "right",
        slots: { customRender: "oversea" }
      },
      {
        title: "BBAC Direct Sales",
        dataIndex: "bbac",
        key: "6",
        align: "right",
        slots: { customRender: "bbac" }
      }
    ];
    const temp=props.breadcrumbName.substr(0,3)
    console.log(temp)
    if (temp=='VAN'){
      blueRightTable=blueRightTable.filter(item=>{
          return item.title!='BBAC Direct Sales'&& item.title!='Oversea'
      })
    }
    if (temp=='CBU'){
     
      blueRightTable[4].title='Price Adjustment';
      blueRightTable[4].dataIndex='priceAdj';
      blueRightTable[4].slots={ customRender: "priceAdj" };
       blueRightTable=blueRightTable.filter(item=>{
          return item.title!='Oversea'
      })
    }
    const blueRightTableData = ref<any[]>([]);
    const getSubOver = () => {
      getSubOverview({ mcId: props.makeId })
        .then(res => {
          dataTable.length = 0;
          blueRightTableData.value.length = 0;
          dataTable.push(...res.totalTable);
          let tempTotal= dataTable[0]? Math.round(dataTable[0].value.replace(/,/g, "")*1) :'';
          tempTotal= tempTotal.toString().replace(/(\d)(?=(?:\d{3})+$)/g,'$1,');
          dataTable[0].value=tempTotal;
          blueRightTableData.value = res.subOverviewItemDtos;
          for (const key in blueRightTableData.value[1]) {
            saveParams.push({
              mcId: props.makeId,
              sdPercent: blueRightTableData.value[1][key],
              type: key
            });
          }
        })
        .catch(err => {
          console.log(err, "报错了");
        });
    };
    getSubOver();
    // 导出按钮
    const exportFile = () => {
      blobDownload({
        url: "/pcapi/closing/blueInvoice/category/export",
        params: { mcId: props.makeId }
      });
    };
    // 保存事件计算蓝票数据
    const handleSave = async () => {
      console.log(saveParams)
      saveParams.forEach(item => {
        
        item.sdPercent = Number((item.sdPercent+'').replace(/[^\-\d.]/g, ""));
      });
      await saveBlueInvoiceInfo(saveParams);
      saveParams=[]
      //获取提示信息
      getTopicInfo();
      //获取表格数据;
      getSubOver();
      context.emit("refresh-table");
    };
    // 保留2位小数
    const saveTwo = (value: string) => {
      let str;
      str = value.replace(/[^\-\d.]/g, "");
      if (str) {
        str = str.replace(/,/g, "");
        const hasPot = str.indexOf(".");
        if (hasPot > -1) {
          const leftNum = str.substring(0, hasPot);
          let rightNum = str.substring(hasPot + 1, str.length);
          if (rightNum.length > 15) {
            rightNum = str.substring(hasPot + 1, hasPot + 3);
          }
          str = leftNum + "." + rightNum;
        }
        return str + "%";
      } else {
        return "";
      }
    };
    const handleInput = (value: string, key: string) => {
      blueRightTableData.value[1][key] = saveTwo(value);
      console.log( key)
      saveParams.forEach((item: any) => {
        console.log()
        if (item.type === key) {
          item.sdPercent = blueRightTableData.value[1][key];
        }
      });
      console.log(saveParams)
    };
    const handleExport = () => {
      const params = {
        url: `/pcapi/forecast/blueInvoice/overview/exportBlueInvoiceInfo`,
        params: {
          mcId: props.makeId
        }
      };
      blobDownload(params);
    };
    return {
      showPrompt,
      getTopicInfo,
      blueRightTable,
      blueRightTableData,
      exportFile,
      handleSave,
      dataTable,
      getSubOver,
      handleInput,
      handleExport
    };
  }
});
