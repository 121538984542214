
import { defineComponent, ref, h,onBeforeUnmount } from "vue";
import { getReportOverview } from "@/API/forecast";
import { ReportData, ExtData, TableHeader } from "@/views/Closing/type";
const tableHeight = window.innerHeight - 350;
import blobDownload from "@/utils/blobDownload";
export default defineComponent({
  props: {
    makeId: {
      type: Number,
      required: true
    },
    tdValue: {
      type: String,
      required: true
    },
    planningId: {
      type: Number,
      required: true
    },
    kufriVersion: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const columns = ref<TableHeader[]>([]);
    const data = ref<ReportData[]>([]);
  const tableKey=ref(false)
    const initData = () => {
        data.value=[];
          tableKey.value=!tableKey.value;
      getReportOverview({
        mcId: props.makeId,
        searchType: parseInt(props.tdValue),
        planningId: props.planningId,
        kufriVersion: props.kufriVersion
      }).then(res => {
        columns.value = res.head;
        columns.value[0].width = "300px";
        columns.value.forEach((item, index) => {
          item.align = "left";
          if (index > 0) item.align = "right";
          if (~item.title.indexOf("Gap")) {
            // Volume负数高亮，SI正数高亮
            item.customRender = (record: any) => {
              if (record.text === "0" || record.text === "0.00%") {
                return h("span", record.text);
              }
              if (
                ~record.record.columnName.indexOf("Volume") &&
                record.text !== ""
              ) {
                return record.text.startsWith("-")
                  ? h("span", { style: "color: red;" }, record.text)
                  : h("span", `+${record.text}`);
              }
              if (
                !~record.record.columnName.indexOf("Volume") &&
                !record.text.startsWith("-")
              ) {
                return h("span", { style: "color: red;" }, `+${record.text}`);
              }
              return h("span", record.text);
            };
          }
          if (!~item.title.indexOf("Gap")) {
            item.customRender = (record: any) => h("span", record.text);
          }
          
        });
        console.log(columns)
        data.value = res.content;
        const generate = (dataArray: ReportData[]) => {
          dataArray.forEach(item => {
            item.ext.forEach((ele: ExtData) => {
              item[ele.keyName] = ele.value;
            });
            if (item.children.length > 0) {
              generate(item.children);
            } else {
              Reflect.deleteProperty(item, "children");
            }
          });
        };
        generate(data.value);
      });
    };
    const handleExport = () => {
      const params = {
        url: `/pcapi/forecast/reportData/exportOverview`,
        params: {
          mcId: props.makeId,
          searchType: parseInt(props.tdValue),
          planningId: props.planningId,
          kufriVersion: props.kufriVersion
        }
      };
      blobDownload(params);
    };
     onBeforeUnmount(()=>{
     data.value=[]
    })
    return {
        tableKey,
      data,
      columns,
      tableHeight,
      initData,
      handleExport
    };
  }
});
